/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        /*--------------------------------------------------
        ** Nav toggle
        --------------------------------------------------*/
        var toggleMenu  = $('.js-nav-toggle');
        var menu        = $('.js-nav-mobile');
        var listItems   = $('.js-nav-mobile li');
        var timeline    = new TimelineMax({ paused: true, reversed: true });

        timeline.fromTo(menu, 0.2, {
          autoAlpha: 0,
          left: '100%',
          ease: Power0.easeNone
        }, {
          autoAlpha: 1,
          left: 0,
          ease: Power4.easeInOut
        });

        timeline.staggerFromTo(listItems, 0.1, {
          css:{
            paddingLeft: '30px',
            opacity: '0'
          },
          ease: Power0.easeInOut
        }, {
          css:{
            paddingLeft: '0px',
            opacity: '1'
          },
          ease: Power0.easeInOut
        }, 0.1, 0.1);

        toggleMenu.on('click', function() {
          $(this).toggleClass('toggled');
          $('body').toggleClass('no-scroll');
          $('.js-header').toggleClass('zindex');

          if (timeline.reversed()) {
            timeline.play();
          } else {
            timeline.reverse();
          }
        });

        $('.js-disabled a').on('click', function() {
          return false;
        });

        /*--------------------------------------------------
        ** Form - Extend textarea on focus
        --------------------------------------------------*/
        if ($('.js-form-message').length) {
          $('.js-form-message .textarea').focus( function() {
            $(this).addClass('focused');
          });

          // $('.js-form-message .textarea').blur( function() {
          //   $(this).removeClass('focused');
          // });
        }

        /*--------------------------------------------------
        ** Datepicker
        --------------------------------------------------*/
        // Get current lang
        var lang = $('html').attr('lang');

        if (lang === 'fr-CA') {
          lang = 'fr-ca';
        } else {
          lang = 'en';
        }

        // current date
        var currentDate = moment().format('YYYY-MM-DD');

        if ($('.js-datepicker').length) {
          // http://eonasdan.github.io/bootstrap-datetimepicker/
          // if ($(window).width() > 767 && !(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
            $('.js-datepicker input').datetimepicker({
              format: "YYYY-MM-DD",
              locale: lang,
              minDate: currentDate,
              // debug: true,
            });
          // }
        }


        /*--------------------------------------------------
        ** Animation - Content centered
        --------------------------------------------------*/
        window.onload = function() {

          var timeline = new TimelineMax();
          timeline
            .from(".js-title-wrapper", 1, {
              y: '5%',
              autoAlpha: 0,
              ease: Back.easeInOut
            }, 0)
            .from(".js-text", 1.5, {
              y: '5%',
              autoAlpha: 0,
              ease: Back.easeInOut
            }, 0);


            TweenMax.from('.js-hr', 1, {
              width: 0,
              autoAlpha: 0,
            });
          
        };


        /*--------------------------------------------------
        ** Animation - Page header
        --------------------------------------------------*/
        // if ($('.js-page-header').length) {

        //   var paddingSize = 200;

        //   if ($(window).width() < 768) {
        //     paddingSize = 70;
        //   }

        //   // init controller
        //   var controller = new ScrollMagic.Controller();

        //   // .js-page-header-content
        //   var pageHeaderTween = TweenMax.from('.js-page-header', 1, {
        //     paddingTop: paddingSize,
        //     paddingBottom: paddingSize,
        //     ease: Power0.easeNone
        //   });

        //   var pageHeader = new ScrollMagic.Scene({
        //     duration: 500,
        //     triggerElement: '.js-page-header',
        //   }).setTween(pageHeaderTween);

        //   // Call all scenes
        //   controller.addScene([
        //     pageHeader,
        //   ]);
        // }


        if ($('.js-reveal').length) {

          $('.js-reveal').each(function() {

            // Init
            var controller      = new ScrollMagic.Controller();
            var element         = this;

            // ELEMENT
            // --------------------------------------------------
            var elementAnim = TweenMax.from(element, 1, {
              y: '10%',
              autoAlpha: 0,
              ease: Back.easeInOut
            });

            var elementScene = new ScrollMagic.Scene({
              triggerElement: element,
              offset: -400,
            }).setTween(elementAnim);

            // Call all scenes
            controller.addScene([
              elementScene
            ]);

          });

        }



        /*--------------------------------------------------
        ** Animation - Section
        --------------------------------------------------*/
        if ($('.js-section').length) {

          if ($(window).width() > 768) {

            $('.js-section').each(function() {

              // Init
              var controller      = new ScrollMagic.Controller();
              var section         = this;
              var sectionWrapper  = $(this).find('.js-section-wrapper');
              var sectionTitle    = $(this).find('.js-title-section');
              var sectionContent  = $(this).find('.js-section-content');
              var sectionBG       = $(this).find('.js-section-bg');


              // BG
              // --------------------------------------------------
              var sectionBGAnim = TweenMax.from(sectionBG, 0.30, {
                autoAlpha: 0,
                ease: Back.easeInOut
              });

              var sectionBGScene = new ScrollMagic.Scene({
                triggerElement: section,
                triggerHook: 1,
                duration: 1000
              }).setTween(sectionBGAnim);


              // TITLE
              // --------------------------------------------------
              var sectionTitleAnim = TweenMax.from(sectionTitle, 0.20, {
                x: '-3%',
                autoAlpha: 0,
                ease: Back.easeInOut
              });

              var sectionTitleScene = new ScrollMagic.Scene({
                triggerElement: section,
                triggerHook: 1,
                duration: 400
              }).setTween(sectionTitleAnim);
              

              // CONTENT
              // --------------------------------------------------

              var sectionContentAnim = TweenMax.from(sectionContent, 0.30, {
                x: '-6%',
                autoAlpha: 0,
                ease: Back.easeInOut
              });

              var sectionContentScene = new ScrollMagic.Scene({
                triggerElement: section,
                triggerHook: 1,
                duration: 600
              }).setTween(sectionContentAnim);


              // Call all scenes
              controller.addScene([
                sectionTitleScene,
                sectionContentScene,
                sectionBGScene
              ]);
            });


          }

          // $(".animate").each(function (index, elem) {
          //   var tween = TweenMax.to(elem, 0.5,
          //                          {scale: 1.02, backgroundColor: 'rgb(255, 39, 46)' });
          //   new ScrollScene({
          //       duration: 200,
          //       triggerElement: elem,
          //       triggerHook: "onCenter",
          //     })
          //     .setTween(tween)
          //     .addTo(controller)
          //     .addIndicators();
          // });
        }

        // Lightbox
        $('.js-video-link').on('click', function() {
          $('.js-lightbox').fadeToggle().addClass('js-toggled');
          $('body').addClass('no-scroll');

          // Play video
          var videoURL = $('.js-youtube-video').prop('src');
          videoURL += "&autoplay=1";
          $('.js-youtube-video').prop('src',videoURL);
          return false;
        });

        var closeLightbox = function() {
          $('.js-lightbox').fadeToggle().removeClass('js-toggled');
          $('body').removeClass('no-scroll');

          // Stop video
          var videoURL = $('.js-youtube-video').prop('src');
          videoURL = videoURL.replace("&autoplay=1", "");
          $('.js-youtube-video').prop('src','');
          $('.js-youtube-video').prop('src',videoURL);

          return false;
        };

        // Lightbox - Close
        $(document).on('click', '.js-close-button', function() {
          closeLightbox();
          return false;
        });

        // Lightbox - Close on escape key
        if ($('.js-lightbox').length) {
          $(document).keyup(function(e) {
            if (e.keyCode === 27) {
              if($('.js-lightbox').hasClass('js-toggled')) {
                closeLightbox();
              }
            }
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // Autofocus on first form field
        if ($('#input_3_2').length) {
          setTimeout(function() {
            $('#input_3_2').focus();
          }, 2000);
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_faq': {
      init: function() {
        $('.js-question').on('click', function() {
          $(this).toggleClass('active').next('.js-answer').slideToggle();
          return false;
        });
      }
    },
    'page_template_template_security': {
      init: function() {
        $('.js-question').on('click', function() {
          $(this).toggleClass('active').next('.js-answer').slideToggle();
          return false;
        });
      }
    },
    'page_template_template_book': {
      init: function() {
        $('.js-flight-one-way').on('click', function() {
          $('.js-return').slideUp();
        });

        $('.js-flight-round-trip').on('click', function() {
          $('.js-return').slideDown();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
